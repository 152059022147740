<template>
  <div>
    <div class="all">
      <div class="my_headers">
        <my_header :msg="title_msg" :states="1"></my_header>
      </div>
      <div class="title">
        <p>筑龙学社课程优惠券</p>
        <p>
          仅剩
          <span>{{ coupon_obj.remain_num }}</span>张
        </p>
      </div>
      <div class="my_coupon">
        <div class="my_couponMain">
          <div class="my_couponMainLeft">
            <p v-if="parseFloat(coupon_obj.discount_radio)" class="money">
              <span class="moneyTxt">{{ coupon_obj.discount_radio_name.slice(0,coupon_obj.discount_radio_name.length-1) }}</span>
              <span style="color: #fff;">折</span>
            </p>
            <p v-else class="money">
              <span class="rmb">￥</span>
              <span class="moneyTxt">{{ parseInt(coupon_obj.money ? coupon_obj.money : 0) }}</span>
            </p>
            <p class="ctext">{{ coupon_obj.left_name }}</p>
            <!-- <p>
              <span>￥</span>
              {{ coupon_obj.money }}
            </p>
            <p>课程优惠券</p> -->
          </div>
          <div class="my_couponMainright">
            <p>{{ coupon_obj.name }}</p>
            <p>
              <span>有效期至</span>
              {{ coupon_obj.end_time }}
            </p>
          </div>
        </div>
      </div>
      <div class="my_btn">
        <button
          id="copy_text"
          class="lr back-confirm btn"
          :data-clipboard-text="url"
          :disabled="disables"
          @click="copy"
        >立即赠送</button>
      </div>
      <div class="foot">
        <p>赠送给好友后，您就不能使用该优惠券了哦~</p>
      </div>
    </div>
    <!-- {{ url }} -->
  </div>
</template>

<script>
import my_header from '@/compontens/heads'
import Clipboard from 'clipboard'
import { Toast } from 'vant'
import { get_ones_yhq } from '@/api/my_user.js'
import Cookies from 'js-cookie'

export default {
  components: {
    my_header
  },
  data() {
    return {
      disables: true,
      url: '',
      title_msg: '筑龙学社',
      // couponMsg:"优惠券链接已复制至剪切板"
      couponMsg: '优惠券链接已复制至剪切板',
      coupon_obj: {}
    }
  },
  mounted() {
    this.getMsg()
  },
  methods: {
    getMsg() {
      var params = {
        id: this.$route.query.id,
        uid: this.$route.query.uid
      }
      get_ones_yhq(params).then(res => {
        if (res.errNo === 0) {
          this.disables = false
          this.coupon_obj = res.result
          this.url = this.$route.query.url_get_coupon
        }
      })
    },
    copy() {
      var _this = this
      var clipboard = new Clipboard('#copy_text')

      clipboard.on('success', (e) => {
        console.log(e.text)
        Toast('已复制地址到剪切板')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        Message({
          message: '该浏览器不支持自动复制',
          type: 'warning'
        })
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.my_couponMainright {
  flex: 1;
  height: 100%;
  // background: chartreuse;
  p:nth-child(1) {
    font-size: 30px;
    color: #333333;
    // text-align: center;
    margin: 0 auto;
    width: 82%;
    padding: 25px 0 0 0;
  }
  p:nth-child(2) {
    width: 82%;
    margin: 0 auto;
    padding: 15px 0 0 0;
    color: #333333;
    span {
      padding-right: 20px;
      color: #ee2e2e;
    }
  }
}
.my_coupon {
  margin-top: 20px;
}
.my_couponMainLeft {
  width: 180px;
  height: 100%;
  // background: #e62929;
  // p:nth-child(1) {
  //   color: #fff;
  //   font-size: 50px;
  //   text-align: center;
  //   padding: 40px 0 0 0;
  //   span {
  //     font-size: 30px;
  //   }
  // }
  // p:nth-child(2) {
  //   color: #fff;
  //   padding-top: 20px;
  //   text-align: center;
  // }
}

.money {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-top: 40px;
  .rmb {
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 24px;
  }
  .moneyTxt {
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 50px;
  }
}
.ctext {
  color: #f5fde3;
  display: block;
  text-align: center;
  padding-top: 10px;
}

.my_btn button {
  width: 684px;
  height: 90px;
  margin: 0 auto;
  display: block;
  margin-top: 130px;
  color: #fff;
  background: #e62929;
  border-radius: 13px;
  font-size: 30px;
  letter-spacing: 5px;
}
.foot p {
  font-size: 28px;
  text-align: center;
  padding-top: 25px;
  color: #999999;
}
.my_couponMain {
  display: flex;
  width: 684px;
  height: 181px;
  margin: 0 auto;
  background: url("../../static/images/my_index/222.png") no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 3px 35px #e1e1e1;
  margin-top: 30px;
}
.all {
  background: url("../../static/images/my_index/givesbg.png") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  width: 100%;
  // position: relative;
  // bottom: 30px;
  // overflow: hidden;
}
.my_headers {
  border-bottom: 1px solid #e1e1e1;
}
.title {
  width: 100%;
  height: 185px;
  p:nth-child(1) {
    font-size: 30px;
    text-align: center;
    padding-top: 64px;
    letter-spacing: 2px;
    color: #494949;
  }
  p:nth-child(2) {
    font-size: 35px;
    text-align: center;
    padding-top: 30px;
    color: #494949;
    letter-spacing: 3px;
    span {
      color: #ee2e2e;
      padding: 0 5px;
    }
  }
}
</style>
